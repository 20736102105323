import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import AgoraRTM from 'agora-rtm-sdk'
import { ApiService } from 'src/app/Services/api.service';
import { AwsUploadService } from 'src/app/Services/aws-upload.service';
import { UtilService } from 'src/app/Services/util.service';
import { environment } from 'src/environments/environment';
import AC from 'agora-chat'


@Injectable({
  providedIn: "root",
})
export class AgoraService {
  client;
  messages = [];
  channelId;
  channel;
  otherPersonIds = [];
  notificationMessage: any;
  data: any;
  fcmTokens;
  conn: any;
  connected: boolean;
  storedMessage = [];
  group_id:any;

  constructor(
    private awsUploadService: AwsUploadService,
    private http: HttpClient,
    private apiService: ApiService,
    private utilsService: UtilService
  ) {
    this.setUpClient();
    this.createConnection();
    this.apiService.dataa$.subscribe(value => {
      if(value != ""){
        this.group_id = value;
      }
      else{
        this.group_id = localStorage.getItem('group_ID')
      }
    });
  }

  setUpClient() {
    this.client = AgoraRTM.createInstance(environment.agora.appId);
  }

  async createConnection() {
    this.conn = new AC.connection({
      appKey: environment.agora.appKey,
    });
    console.log(this.conn, "this.conn41");
  }

  async loginToAgoraSDK(userId, token) {
    this.conn.open({
      user: userId,
      agoraToken: token,
    });
    this.getGroupMessage();
  }

  async listenToChannel(chatChannelId, chatToken, chatUDDID) {
    // console.log("Line 56","Error",environment.agora.appId)
    if (chatChannelId == this.channelId) {
      return;
    }
    this.channelId = chatChannelId;
    this.channel = this.client.createChannel(chatChannelId);
    await this.channel.join();
    let cb = (message) => {
      let messageObj = JSON.parse(message.text);
      console.log(this.messages, "messages");
      this.messages.push(messageObj);
      this.scrollToBottom();
      this.utilsService.showToast(
        "Success",
        `Message Reveived in ` + localStorage.getItem("classroom") + " class"
      );
    };
    this.channel.on("ChannelMessage", cb);
  }

  async sendMessageToChannel(successcallback, channelMessage) {
    // console.log(channelMessage, "channelMessage")
    this.messages.push(channelMessage);
    console.log(this.messages, "this.messages 74444");
    this.scrollToBottom();
    await this.channel
      .sendMessage(
        { text: JSON.stringify(channelMessage) },
        { enableHistoricalMessaging: true }
      )
      .then((sendResult) => {
        if (successcallback) {
          successcallback(sendResult);
        }
      });
  }

  async sendMsgToGroup(option) {
    let msg = AC.message.create(option);
    this.conn
      .send(msg)
      .then((res) => {
        this.messages.push(option);    
        this.scrollToBottom();
        console.log("Send message success", res);
      })
      .catch((e) => {
        console.log("Send message fail", e);
      });
  }

  getGroupMessage() {
    this.conn.addEventHandler("eventName", {
      onOpened: () => {
        console.log("on opened >>>>>>>");
      },
      // Occurs when the connection is lost.
      onClosed: () => {
        console.log("on closed >>>>>>>>>");
      },
      // Occurs when the text message is received.
      onTextMessage: (message: any) => {
        if(message.to == this.group_id){
          let messageObj = message;
          this.messages.push(messageObj);
          this.scrollToBottom();
        }
        else{
          console.log('message');
        }
      },
      // Occurs when the image message is received.
    onImageMessage: (message:any)=>{
      if(message.to == this.group_id){
        let messageObj = message;
        this.messages.push(messageObj);
        this.scrollToBottom();
      }
      else{
        console.log('message');
      }
    },
    // Occurs when the audio message is received.
    onAudioMessage:(message:any) =>{
      if(message.to == this.group_id){
        let messageObj = message;
        this.messages.push(messageObj);
        this.scrollToBottom();
      }
      else{
        console.log('message');
      }
    },
    // Occurs when the file message is received.
    onFileMessage:(message:any)=>{
      if(message.to == this.group_id){
        let messageObj = message;
        this.messages.push(messageObj);
        this.scrollToBottom();
      }
      else{
        console.log('message');
      }
    },
    // Occurs when the video message is received.
    onVideoMessage:(message:any)=>{
      if(message.to == this.group_id){
        let messageObj = message;
        this.messages.push(messageObj);
        this.scrollToBottom();
      }
      else{
        console.log('message');
      }
    }
    });
  }

  // .getHistoryMessages({
  //   // cursor:'1257037204748766182',
  //   targetId: "241681935958018", // The user ID of the peer user for one-to-one chat or group ID for group chat.
  //   chatType: "groupChat", // The chat type: `singleChat` for one-to-one chat or `groupChat` for group chat.
  //   pageSize: 50, // The number of messages to retrieve per page. The value range is [1,50] and the default value is 20.
  //   searchDirection: "down", // The message search direction: `up` means to retrieve messages in the descending order of the message timestamp and `down` means to retrieve messages in the ascending order of the message timestamp.
  //   searchOptions: {
  //     // from: this.from, // The user ID of the message sender. This parameter is used only for group chat.
  //     // msgTypes: ['txt'], // An array of message types for query. If no value is passed in, all types of message will be queried.
  //     startTime: new Date("2023,11,9").getTime(), // The start timestamp for query. The unit is millisecond.
  //       endTime: new Date("2024,03,15").getTime(), // The end timestamp for query. The unit is millisecond.
  //       },
  //     })

  async groupchathistory(groupId) {
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    let endTime = tomorrow.getTime();
    await this.conn
      .getHistoryMessages({
        // cursor:'1257037204748766182',
        targetId: groupId,
        chatType: "groupChat",
        pageSize: 3000,
        searchDirection: "up",
        searchOptions: {
          startTime: new Date("2023,11,9").getTime(),
          endTime: endTime,
        },
      })
      .then((res: any) => {
        let messageObj = res.messages;
        this.storedMessage = [...messageObj, ...this.messages];
        const uniqueIds = new Set<string>();
        const uniqueMessages = this.storedMessage.filter((msg: any) => {
          if (!uniqueIds.has(msg.id)) {
            uniqueIds.add(msg.id);
            return true;
          }
          return false;
        });
        this.storedMessage = uniqueMessages;
      });
    return this.storedMessage;
  }

  addMediaMessage(input,channelID,id,groupID) {
    let file = AC.utils.getFileUrl(input);
    let fileType;
    let allowType;
    if (file.data["type"].includes("image")) {
      fileType = "img";
      allowType = {
        jpg: true,
        gif: true,
        png: true,
        bmp: true,
        jpeg:true,
      };
    } else if (file.data["type"].includes("audio/mpeg")) {
      fileType = "audio";
      allowType = {
        'mp3': true,
        'amr': true,
        'wmv': true
    };
    }else if (file.data['type'].includes("wav")) {
      fileType = "audio";
      allowType = {
        'wav':true
    };
    }else if (file.data['type'].includes("video")) {
      fileType = "video";
      allowType = {
        mp4: true,
        wmv: true,
        avi: true,
        rmvb: true,
        mkv: true,
      };
    } else {
      fileType = "file";
      allowType = {
        jpg: true,
        gif: true,
        png: true,
        bmp: true,
        zip: true,
        txt: true,
        doc: true,
        pdf: true,
        xls: true,
        xlsx: true,
        docx:true,
        pptx:true,
      };
    }
    if (file.filetype.toLowerCase() in allowType) {
      // debugger
      let option: any = {
        // Set the message type.
        type: fileType,
        file: file,
        // filename:file.filename,
        ext: {
          // Set the image file length.
          file_length: file.data.size,
        },
        // Set the username of the message receiver.
        to: groupID,
        // Set the chat type.
        chatType: "groupChat",
        // loadingProgress: true,
        channelId: channelID,
        from: id,
        time: Date.now(),
        content: "",
        url:file.url,
        filename:file.filename,
        // Occurs when the image file fails to be uploaded.
        onFileUploadError: function () {
          console.log("onFileUploadError");
        },
        // Reports the progress of uploading the image file.
        onFileUploadProgress: function (e) {
          console.log(e);
        },
        // Occurs when the image file is successfully uploaded.
        onFileUploadComplete: function () {
          console.log("onFileUploadComplete");
        },
      };
      // Create a voice message.
      let msg = AC.message.create(option);
      
      // Call send to send the voice message.
      this.conn
        .send(msg)
        .then((res) => {
          this.messages.push(option);
          this.scrollToBottom();
          // Occurs when the audio file is successfully sent.
          console.log("Success");
        })
        .catch((e) => {
          // Occurs when the audio file fails to be sent
          console.log("Fail");
        });
    }
    else{
      this.utilsService.showToast('Error',"File type not allowed");
    }
  }

  addAudioMessage(input,url,groupID,ID,blob,channel,duration){
    const myFile = new File([blob], 'testing.mp3', {
      type: blob.type,
  });

 const customFile = {
  data:myFile,
  filename:"audio_file.mp3",
  filetype:"mp3",
  url:url
 }
      let option: any = {
        type: 'audio',
        file: customFile,
        ext: {file_length: input.size,},
        to: groupID,
        chatType: 'groupChat',
        channelId: channel,
        from:ID,
        length:duration,
        time: Date.now(),
        content: "",
        url:url,
        filename:'audio_file.mp3',
        // Occurs when the audio file fails to be uploaded.
        onFileUploadError: function () {
            console.log('onFileUploadError');
        },
        // Reports the progress of uploading the audio file.
        onFileUploadProgress: function (e) {
            console.log(e)
        },
        // Occurs when the audio file is successfully uploaded.
        onFileUploadComplete: function () {
            console.log('onFileUploadComplete');
        },
        
      };
      // Create a voice message.
      let msg = AC.message.create(option);
      
      // Call send to send the voice message.
      this.conn
        .send(msg)
        .then((res) => {
          this.messages.push(option);
          this.scrollToBottom();
          // Occurs when the audio file is successfully sent.
          console.log("Success");
        })
        .catch((e) => {
          // Occurs when the audio file fails to be sent
          console.log("Fail");
        });
      
  }

  async sendInvitationForCall(reciverID) {
    // console.log(reciverID)
    return this.client.createLocalInvitation(JSON.stringify(reciverID));
  }

  async loginToAgora(chatToken, chatUDDID) {
    // console.log(chatUDDID)
    return this.client.login({
      uid: chatUDDID,
      token: chatToken,
    });
  }

  async logOutToChannel() {
    await this.client.logout();
  }
  // addMediaMessage(file, channelId, id, seconds) {
  //   let fileType;
  //   if (file['type'].includes("image")) {
  //     fileType = "IMAGE"
  //   } else if (file['type'].includes("audio/mpeg")) {
  //     fileType = "AUDIO"
  //   } else if (file['type'].includes("video")) {
  //     fileType = "VIDEO"
  //   }
  //   else {
  //     fileType = "FILE"
  //   }

  //   let obj = {
  //     type: fileType,
  //     duration: seconds,
  //     channelId: channelId,
  //     fileSize: this.calculateMB(file.size),
  //     senderId: id,
  //     fileName: file.name,
  //     loadingProgress: true,
  //     timeStamp: Date.now(),
  //     content: ""
  //   };
  //   this.handleNewMessage(file.name, channelId)
  //   // console.log(file)
  //   this.messages.push(obj)
  //   this.scrollToBottom()
  //   let mediaMessageIndex = this.messages.length - 1;
  //   let successcallback = (result) => {
  //     this.messages.splice(mediaMessageIndex, 1)
  //     delete obj["loadingProgress"]
  //     obj["content"] = result['Location']
  //     this.sendMessageToChannel(null, obj)
  //   }
  //   this.awsUploadService.uploadFileToS3(file, successcallback, null)
  // }

  calculateMB(fileSize) {
    // console.log(fileSize)
    let size;
    if (Number(fileSize) < 1048576) {
      size = (Number(fileSize) / 1024).toFixed(2);
      // console.log(size)
      return size + " KB";
    } else {
      size = (Number(fileSize) / (1024 * 1024)).toFixed(2);
      // console.log("Size",size)
      return size + " MB";
    }
  }

  scrollToBottom() {
    let chatLogDom = document.getElementById("chat-log-container");
    setTimeout(() => {
      chatLogDom.scrollBy(0, chatLogDom.scrollHeight);
    }, 0);
  }

  async handleNewMessage(message, questionId) {
    let user = (await this.apiService.getAuthUser()).attributes;
    let webtoken = [];
    let iostoken = [];
    // console.log(this.fcmTokens)
    this.fcmTokens.forEach((element) => {
      if (element.device_type == 2 || element.device_type == 1) {
        element.device_tokens.forEach((ele) => {
          iostoken.push(ele);
        });
      } else if (element.device_type == 3) {
        element.device_tokens.forEach((ele) => {
          webtoken.push(ele);
        });
      }
    });
    let redirectUrlID = questionId.split("_");
    let body = {
      data: {
        title: "New Message Recieve",
        message: "Text Message",
        classroomId: redirectUrlID[1],
        url: `${environment.app_url}/#/admin/classroom/${redirectUrlID[1]}`,
        type: 11,
      },
      registration_ids: webtoken,
    };
    //  console.log(body)
    this.http
      .post("https://fcm.googleapis.com/fcm/send", body, {
        headers: this.getHeader(),
      })
      .subscribe((res) => {
        // console.log(res)
      });
    if (iostoken.length) {
      let iosbody = {
        notification: {
          body: "New Message Received",
          title: "Text Message",
        },
        data: {
          body: "Text Message",
          title: "New Message Received",
          channelId: questionId,
          question_id: redirectUrlID[1],
          user_name: user["custom:name"],
          type: 11,
        },
        registration_ids: iostoken,
      };
      //  console.log(iosbody)
      this.http
        .post("https://fcm.googleapis.com/fcm/send", iosbody, {
          headers: this.getHeader(),
        })
        .subscribe((res) => {
          // console.log(res)
        });
    }
    this.scrollToBottom();
  }

  public getHeader() {
    let token: any = environment.firebase_server_key;
    const headers: any = new HttpHeaders({
      Authorization: "key=" + token,
      "Content-Type": "application/json",
    });
    return headers;
  }
}
