let state = "DEV"
const devObj = {
  OldJOINUS: "http://3.231.233.64/doceo/v1/api/",
  JOINUS: "https://dev.doceoapp.com/doceo/v1/api/",
  "publishableKey": "pk_live_51Jv1xgAivPp5GQ0vZoxAomKS66eMaoQdZcOa3RU0HJaWAVhEyCcPsR1XH5CTverGby3gJIDHqUSl42HW7uMata9d0024sq4VrD",
  getCountryCode:"https://ipapi.co/json/",
  agoraURL:"https://api.agora.io/dev/v2/project/",
  oauth: {
    domain: 'doceoappdev.auth.us-east-1.amazoncognito.com',
    redirectSignIn: 'https://devweb.doceoapp.com?',
    redirectSignOut: 'https://devweb.doceoapp.com?',
    responseType: 'token'
  },
  __agora:{
    appId:"41530174384f49018d7a292fb9345899"
  },
  _agora:{
    appId:"7764c5e02bbc40fea5545cf6fb66f178",
  },
  agora:{
    appId:"3b9c3e29b4a44f3aa285036f49bbd5b6",
    appKey:"41418076#489793"
  },
  awsBucket: {
    secretAccessKey: "rkK2MAEJRsihvXJ+Bk7+tjaNNNEuSn4RUde48ya4",
    accessKeyId: "AKIASAIGYGY4WA4BV7GO",
    bucketName: "doceo-dev",
    regionName: "us-east-1"
  },
  amplify: {
    secretAccessKey: "5v86ov34f05tFIQ4/lt39jQKQngzqL0WnFJ5+QmB",
    accessKeyId: "AKIAXNKC7PW27HW56653",
    userPoolId: "us-east-1_bEm0gLGMJ",
    region: "us-east-1",
    userPoolWebClientId: "3b9g15qkepn0e50nou11q0i54o"
  },
  googleTranslationServiceKey: 'AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U',
  adsense: {
    // do not pass any thing here if it is not production server..
    adClient: '',
    adSlot1:'',
    adSlot2:'',
    adSlot3:'',
    adSlot4:'',
    adSlot5:'',
    adSlot6:'',
    show:false,
    async : true,
    src : "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=",
    crossorigin: "anonymous"
  },
  firebase: {
    apiKey: "AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U",
    authDomain: "doceo-d3180.firebaseapp.com",
    projectId: "doceo-d3180",
    storageBucket: "doceo-d3180.appspot.com",
    messagingSenderId: "294495425597",
    appId: "1:294495425597:web:f4de5d7643f1aa223bc654",
    measurementId: "G-32LDBHM9SD",
    vapidKey:'BKFsF_s4QOQ2F9Qr3EPLzbbzmDs_NmOtCGttimjLUDGOAKODECTlNPi_cqOg0-dcylg_CXlBsBwvG5xAcHZKAzE'
  },
  firebase_server_key: 'AAAARJFLlD0:APA91bFD_K2L0bsQR6teSo7cY4a4Oi5IOhfCar7UWsXBz_xNBvttSJjtgif7u3lv0TgyW1Pt4oKdDh3tzLdWLnpA5nK5H2wHUyQNhnCa_DKk_mz2pRSMW1GsmwWoJ4zFNPkR1YsJDgaF',
  app_url: "https://d1ajf0qv15vdab.cloudfront.net"
};

const stageObj = {
  JOINUS: "https://stage.doceoapp.com/doceo/v1/api/",
  "publishableKey": "pk_live_51Jv1xgAivPp5GQ0vZoxAomKS66eMaoQdZcOa3RU0HJaWAVhEyCcPsR1XH5CTverGby3gJIDHqUSl42HW7uMata9d0024sq4VrD",
  getCountryCode:"https://ipapi.co/json/",
  agoraURL:"https://api.agora.io/dev/v2/project/",
  oauth: {
    domain: 'doceoappdev.auth.us-east-1.amazoncognito.com',
    redirectSignIn: 'https://d1ajf0qv15vdab.cloudfront.net?',
    redirectSignOut: 'https://d1ajf0qv15vdab.cloudfront.net?',
    responseType: 'token'
  },
  _agora:{
    appId:"41530174384f49018d7a292fb9345899"
  },
  __agora:{
    appId:"7764c5e02bbc40fea5545cf6fb66f178",
  },
  agora:{
    appId:"3b9c3e29b4a44f3aa285036f49bbd5b6",
    appKey:"41418076#489793"
  },
  awsBucket: {
    secretAccessKey: "rkK2MAEJRsihvXJ+Bk7+tjaNNNEuSn4RUde48ya4",
    accessKeyId: "AKIASAIGYGY4WA4BV7GO",
    bucketName: "doceo-dev",
    regionName: "us-east-1"
  },
  amplify: {
    secretAccessKey: "5v86ov34f05tFIQ4/lt39jQKQngzqL0WnFJ5+QmB",
    accessKeyId: "AKIAXNKC7PW27HW56653",
    userPoolId: "us-east-1_bEm0gLGMJ",
    region: "us-east-1",
    userPoolWebClientId: "3b9g15qkepn0e50nou11q0i54o"
  },
  googleTranslationServiceKey: 'AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U',
  adsense: {
    // do not pass any thing here if it is not production server..
    adClient: '',
    adSlot1:'',
    adSlot2:'',
    adSlot3:'',
    adSlot4:'',
    adSlot5:'',
    adSlot6:'',
    show:false,
    async : true,
    src : "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=",
    crossorigin: "anonymous"
  },
  firebase: {
    apiKey: "AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U",
    authDomain: "doceo-d3180.firebaseapp.com",
    projectId: "doceo-d3180",
    storageBucket: "doceo-d3180.appspot.com",
    messagingSenderId: "294495425597",
    appId: "1:294495425597:web:f4de5d7643f1aa223bc654",
    measurementId: "G-32LDBHM9SD",
    vapidKey:'BKFsF_s4QOQ2F9Qr3EPLzbbzmDs_NmOtCGttimjLUDGOAKODECTlNPi_cqOg0-dcylg_CXlBsBwvG5xAcHZKAzE'
  },
  firebase_server_key: 'AAAARJFLlD0:APA91bFD_K2L0bsQR6teSo7cY4a4Oi5IOhfCar7UWsXBz_xNBvttSJjtgif7u3lv0TgyW1Pt4oKdDh3tzLdWLnpA5nK5H2wHUyQNhnCa_DKk_mz2pRSMW1GsmwWoJ4zFNPkR1YsJDgaF',
  app_url: "https://d1ajf0qv15vdab.cloudfront.net"
}

const prodObj = {
  JOINUS: "http://3.231.233.64/doceo/v1/api/",
  "publishableKey": "pk_live_51Jv1xgAivPp5GQ0vZoxAomKS66eMaoQdZcOa3RU0HJaWAVhEyCcPsR1XH5CTverGby3gJIDHqUSl42HW7uMata9d0024sq4VrD",
  getCountryCode:"https://ipapi.co/json/",
  agoraURL:"https://api.agora.io/dev/v2/project/",
  oauth: {
    domain: 'doceoappdev.auth.us-east-1.amazoncognito.com',
    redirectSignIn: 'https://devweb.doceoapp.com?',
    redirectSignOut: 'https://devweb.doceoapp.com?',
    responseType: 'token'
  },
  agora:{
    appId:"41530174384f49018d7a292fb9345899",
    appKey:"41418076#489793"
  },
  __agora:{
    appId:"7764c5e02bbc40fea5545cf6fb66f178",
  },
  _agora:{
    appId:"3b9c3e29b4a44f3aa285036f49bbd5b6",
  },
  awsBucket: {
    secretAccessKey: "rkK2MAEJRsihvXJ+Bk7+tjaNNNEuSn4RUde48ya4",
    accessKeyId: "AKIASAIGYGY4WA4BV7GO",
    bucketName: "doceo-dev",
    regionName: "us-east-1"
  },
  amplify: {
    secretAccessKey: "5v86ov34f05tFIQ4/lt39jQKQngzqL0WnFJ5+QmB",
    accessKeyId: "AKIAXNKC7PW27HW56653",
    userPoolId: "us-east-1_bEm0gLGMJ",
    region: "us-east-1",
    userPoolWebClientId: "3b9g15qkepn0e50nou11q0i54o"
  },
  googleTranslationServiceKey: 'AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U',
  adsense: {
    adClient: '',
    adSlot1:'',
    adSlot2:'',
    adSlot3:'',
    adSlot4:'',
    adSlot5:'',
    adSlot6:'',
    show:true,
    async : true,
    src : "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=",
    crossorigin: "anonymous"
  },
  firebase: {
    apiKey: "AIzaSyCO2juGXJaN4unCB3CcoP9ada31ALzXe8U",
    authDomain: "doceo-d3180.firebaseapp.com",
    projectId: "doceo-d3180",
    storageBucket: "doceo-d3180.appspot.com",
    messagingSenderId: "294495425597",
    appId: "1:294495425597:web:f4de5d7643f1aa223bc654",
    measurementId: "G-32LDBHM9SD",
    vapidKey:'BKFsF_s4QOQ2F9Qr3EPLzbbzmDs_NmOtCGttimjLUDGOAKODECTlNPi_cqOg0-dcylg_CXlBsBwvG5xAcHZKAzE'
  },
  firebase_server_key: 'AAAARJFLlD0:APA91bFD_K2L0bsQR6teSo7cY4a4Oi5IOhfCar7UWsXBz_xNBvttSJjtgif7u3lv0TgyW1Pt4oKdDh3tzLdWLnpA5nK5H2wHUyQNhnCa_DKk_mz2pRSMW1GsmwWoJ4zFNPkR1YsJDgaF',
  app_url: "https://d1ajf0qv15vdab.cloudfront.net"
}
export const environment = (state == "STAGE" ? stageObj : (state == "DEV" ? devObj : prodObj))
