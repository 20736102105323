import { Component } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';
import { ApiService } from './Services/api.service';
import { AgoraService } from './admin/chat-module/agora.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>
  <div id="global-spinner-overlay" class="global-spinner-overlay">
  <div class="spinner-border m-5" role="status">
  <span class="sr-only"></span>
  </div>
  </div>`,
  styles: [`
  #global-spinner-overlay{
    position: fixed;
    display:none;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #00000030;
    padding-top: 100px;
    text-align:center
}`]
})
export class AppComponent {
  constructor(private router: Router,private apiService: ApiService,private agoraService:AgoraService) {
    try {
      Amplify.configure({
        Auth: environment.amplify
      });
      Auth.configure({oauth:environment['oauth']});
    } catch (error) {
    }
    this.apiService.getCountryCode()
    let url = window.location.href
    let tempUrl = url.split("?#").join("?")
    if (url.indexOf("?#") > -1) {
      let tempUrlParams = new URLSearchParams((new URL(tempUrl)).search)
      let accessToken = tempUrlParams.get("access_token")
      let decoded = jwt_decode(accessToken);
      let username = decoded["username"]
      let idToken = tempUrlParams.get("id_token")
      const keyPrefixWithUsername = `CognitoIdentityServiceProvider.${environment.amplify.userPoolWebClientId}.${username}`
      window.localStorage.setItem(`${keyPrefixWithUsername}.idToken`, idToken)
      window.localStorage.setItem(`${keyPrefixWithUsername}.accessToken`, accessToken)
      window.localStorage.setItem(`${keyPrefixWithUsername}.clockDrift`, "16")
      window.localStorage.setItem(`CognitoIdentityServiceProvider.${environment.amplify.userPoolWebClientId}.LastAuthUser`, username)
      this.apiService.markSocialLogin(username,accessToken)
      this.router.navigateByUrl("/admin/update-role")
    }
  }
}
