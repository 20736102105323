import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { urls } from '../global';
let AWS = require("aws-sdk");
AWS.config = {
  accessKeyId: environment.amplify.accessKeyId,
  secretAccessKey:environment.amplify.secretAccessKey,
  region: environment.amplify.region,
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  signupNumber;
  planData:any;
  signUpFormData: any;
  parentConsentData:any;
  changeEmail:boolean;
  changeEmailvalue: any;
  forgotPasswordData;
  isLogged = new BehaviorSubject<boolean>(false);
  userInfo: any;
  countryCode: any;
  activeRoleIsStudent: boolean;
  activeChannels=[];
  emailVerified: boolean = false;
  verifyEmailOTP: boolean = false;
  verifyEmailAddress: any;
  verifyPhoneNumber: any;
  verifyPhoneOTP: boolean = false;
  phoneVerified: boolean = false;
  user_name
  profileEmail: boolean = false;
  unreadCount;
  isUnreadNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  questionNotificationId: any;
  profileData: any;
  parentConsentEmail: any;

  constructor(private http: HttpClient,private router: Router) {
  }

  logOut(){
    Auth.signOut().then();
    localStorage.clear();
    window.location.href=""
  }


  async isLoggedIn(){
    try{
      await Auth.currentAuthenticatedUser()
      return true
    }catch(error){
      return false
    }
  }

  forgotPassword(userName):Promise<any>{
    return Auth.forgotPassword(userName)
  }

  loginWithUsernamePassword(username, password){
    return Auth.signIn(username, password)
  }

  async getAuthUser(){
    return Auth.currentAuthenticatedUser()
  }

  confirmForgotPassword(userName,otp,newPassword){
    return Auth.forgotPasswordSubmit(userName,otp,newPassword)
  }

  confirmSignUp(username,code){
    return Auth.confirmSignUp(username,code)
  }

  resendSignOTPOnMobile(username,updatedAttributes?){
    return Auth.resendSignUp(username)
  }

  changePassword(user, oldPassword, newPassword){
    return Auth.changePassword(user, oldPassword, newPassword)
  }

  verifyEmail(username, att){
    return Auth.verifyUserAttribute(username,att)
  }

  verifyEmailwithCode(user, att, code){
    return Auth.verifyUserAttributeSubmit(user, att, code)
  }

  updateCognitoUser(username,updatedAttributes,successfulCallback,failureCallback) {
    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      { 
        apiVersion: "2021-11-18",
        UserPoolId: environment.amplify.userPoolId,
        }
    );
    let params = {
      UserPoolId: environment.amplify.userPoolId,
      Username:  username,
      UserAttributes:updatedAttributes
    };
    cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(err, data) {
      if (err) failureCallback(err); 
      else if(successfulCallback){
        successfulCallback(data)
      }
    });
  }

  checkifUserExist(mobileNumber){
    return this.genericGET(environment.JOINUS+`user/validate/entity/${mobileNumber}`,null)
  }

  checkifUserConfirm(mobileNumber){
    return this.genericGET(environment.JOINUS+`user/check/status?phone_number=${mobileNumber}`,null)
  }

  checkifByEmailUserExist(email){
    return this.genericGET(environment.JOINUS+`user/validate/entity/${email}`,null)
  }

  loginWithUserPassword(userName: string, password: string): Promise<any> {
    return Auth.signIn(userName, password)
  }

  genericGET(url: string, params) {
      return this.http.get(this.getParameters(url, params))
  }
  

  async genericGETWithToken(url: string, params) {
      return this.http.get(this.getParameters(url, params),{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }


  genericPOST(url: string, params, obj) {
      return this.http.post(this.getParameters(url, params), obj)
  }

  async genericPOSTWithToken(url: string, params, obj) {
      return this.http.post(this.getParameters(url, params), obj,{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async genericPUTWithToken(url: string, params, obj) {
    return this.http.put(this.getParameters(url, params), obj,{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async genericPATCHWithToken(url: string, params, obj) {
    return this.http.patch(this.getParameters(url, params), obj,{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
}

  genericPATCH(url: string, params, obj) {
    return this.http.patch(this.getParameters(url, params), obj)
  }

  getParameters(url, params) {
    let paramsString = ''
    if (params) {
      paramsString = new URLSearchParams(params).toString();
    }
    return `${url}${paramsString ? ('&' + paramsString) : ""}`
  }

  joinus(obj) {
    return this.genericPOST(environment.JOINUS + "user/join-us", null, obj);
  }

  signUp(obj) {
    // console.log(obj)
    return Auth.signUp(obj)
  }

  saveNewUser(obj) {
    return this.http.post(environment.JOINUS + 'user/signup', obj)
  }

  loginWithFacebook() {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
  }
  loginWithGoogle() {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
  }
  loginWithApple() {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })
  }

  getSubscriptions(){
    return this.genericGET(environment.JOINUS+"subscription/master?", {platform_type:1})
  }

  getLanguages(filter){
    return this.http.get(environment.JOINUS + `user/language/master${filter?filter:''}`)
  }

  async updateLanguage(obj){
    return this.http.post(environment.JOINUS + 'user/language/prefrence', obj,{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async updateSubscription(obj){
    return this.http.put(environment.JOINUS + 'subscription/update', obj,{headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }
  
  subscription(obj){
    return this.genericGETWithToken(environment.JOINUS +"subscription/session?",obj)
  }

  async updateMyAttributes(value){
    let user = await Auth.currentAuthenticatedUser()
    return Auth.updateUserAttributes(user,value)
  }

  getMasterGrades() {
    return this.genericGET(environment.JOINUS+"student/master/grades",null)
  }

  getMasterGradesSubjectCategory(grade_id?){
    return this.genericGET(environment.JOINUS+"student/master/subject/category?",(grade_id?{grade_id}:null))
  }

  getMasterGradesCategorySubjectStream(category_id) {
    return this.genericGET(environment.JOINUS+"student/master/subject/stream?",{category_id})
  }

  submitQuestion(data,edit?){
    if(edit){
      return this.genericPUTWithToken(environment.JOINUS +"student/question",null,data)
    }else{
      return this.genericPOSTWithToken(environment.JOINUS +"student/question",null,data)
    }
  }

  updateTutorProfile(data){
      return this.genericPOSTWithToken(environment.JOINUS +"tutor/profile/update",null,data)
  }

  async getLoggedUserToken(){
    try{
      let data = await Auth.currentSession()
      return data['idToken'].jwtToken
    }catch(error){
      window.location.href=""
      setTimeout(() => {
        window.location.href = "/auth/login"
      },2000)
    }
  }

  updateUserPhone(obj) {
    return this.http.put(this.getParameters(environment.JOINUS+"user/update/phone-number", null),obj)
  }

  loginWithToken(token){
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.amplify.userPoolId,
      Logins: {
        [`cognito-idp.${environment.amplify.region}.amazonaws.com/${environment.amplify.userPoolId}`]: token["access_token"]
      }
    });
  }

  saveNewUserRole(obj){
    return this.genericPATCH(environment.JOINUS+'user/update-role',null,obj)
  }

  getCountryCode() {
    this.genericGET(environment['getCountryCode'],null).subscribe((response) => {
      this.countryCode =  response["country_calling_code"]
    })
  }

  markSocialLogin(username, token) {
    this.saveNewUser({
      "signup_type": username.indexOf("facebook") > -1 ? "3" : "2",
      "token": token
    }).subscribe(async response => {
      let awsUserData = {
        "custom:user_id":JSON.stringify(response["result"]["id"])
      }
      try{
        let user = await Auth.currentAuthenticatedUser();
        Auth.updateUserAttributes(user, awsUserData).then()
        if(!user["custom:role"]){
          this.router.navigateByUrl("/admin/update-role")
        }
      }catch(error){
      }
    })
  }

  getQuestions(status,limit,page_number,student_id){
    return this.genericGETWithToken(environment.JOINUS +"student/question/list?",{
      status:status,
      page_number:page_number,
      limit:limit,
      student_id:student_id
    })
  }

  getTutorQuestions(status,limit,page_number,tutor_id){
    return this.genericGETWithToken(environment.JOINUS +"tutor/question/list?",{
      page_number:page_number,
      limit:limit,
      tutor_id:tutor_id
    })
  }

  getQuestionById(id){
    return this.genericGETWithToken(environment.JOINUS +"student/question/detail?",{
      question_id:id
    })
  }
  getTimeZone(){
    return this.genericGET(environment.JOINUS +"user/timezone/master",null)
  }

  saveMyAvailability(data){
    return this.genericPOSTWithToken(environment.JOINUS +"tutor/availability",null,data)
  }

  updateMyAvailability(data){
    return this.genericPUTWithToken(environment.JOINUS +"tutor/availability",null,data)
  }

  getChatToken(data){
    return this.genericGETWithToken(environment.JOINUS +"user/get-session-token?",data)
  }

  updateQuestionStatusByTutor(data){
    return this.genericPATCHWithToken(environment.JOINUS +"tutor/question/action",null,data)
  }

  fetchOldChatQuery(channelId,token,uid){
    // console.log(token)
    return this.http.post(`${environment.agoraURL}${environment.agora.appId}/rtm/message/history/query`,
    {
      "filter": {
        "destination": channelId,
        "start_time": "2019-08-01T01:22:10Z",
        "end_time": new Date().toISOString()
      },
      "offset": 0,
      "limit": 1000,
      "order": "asc"
    }
    ,{headers:new HttpHeaders().set('x-agora-token', token).set("x-agora-uid",uid)})
  }

  fetchOldChat(url,token,uid){
    return this.http.get(environment.agoraURL+environment.agora.appId+url,{headers:new HttpHeaders().set('x-agora-token', token).set("x-agora-uid",uid)})
  }

  async fetchOldChatsFromBackEnd(obj){
    return this.genericGETWithToken(environment.JOINUS+'user/common/classroom/chats?',obj);
  }



  fetchMessageList(handle,token,uid){
    return this.http.get(environment.agoraURL+environment.agora.appId+'/rtm/message/history/query/'+handle,{headers:new HttpHeaders().set('x-agora-token', token).set("x-agora-uid",uid)})
  }

  getAllClassroomForChat(role_id,user_id){
    return this.genericGETWithToken(environment.JOINUS+'classroom/chat/questions?',{role_id,user_id})
  }

  getPreSessionToken(user_id){
    return this.genericGETWithToken(environment.JOINUS+'user/pre-session-token?',{user_id})
  }

  updateQuestionMarkAsComplete(data){
    return this.genericPATCHWithToken(environment.JOINUS + "student/question/mark-as-completed",null,data);
  }

  async get(path) {
    return this.http.get(path, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async post(path, data){
    return this.http.post(path, data, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  sendParentEmail(path, data){
    return this.http.post(path, data)
  }

  getParentConsentData(path){
    return this.http.get(path)
  }

  sendParentFormData(path, body){
    return this.http.patch(path, body)
  }

  async switchProfile(body){
    return this.http.patch(urls.switchProfile, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async getProfileData(params){
    return this.http.get(urls.getProfileData+`?user_id=${params}`, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async updateProfile(body){
    return this.http.put(urls.updateProfileData,body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async deleteAccount(user_id){
    return this.http.delete(urls.deleteAccount+`/${user_id}`, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async getSelectedLanguage(id){
    return this.http.get(urls.getSelectedLanguage+`/${id}`, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async setOnlineStatus(body){
    return this.http.patch(urls.setAvailabilityStatus, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async setReportSettings(body){
    return this.http.post(urls.setReportSetting, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async updateReportSettings(body){
    return this.http.put(urls.setReportSetting, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async getReportSettings(url){
    return this.http.get(url, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async getNotificationList(url){
    return this.http.get(url, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async deleteNotification(url){
    return this.http.delete(url, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async updateNotification(body){
    return this.http.patch(urls.updateNotificaationStatus, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async repostQuestion(body){
    return this.http.patch(urls.repostQuestion, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async getPermission(url){
    return this.http.get(url, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async setPermission(body){
    return this.http.patch(urls.setPermission, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  async pushIOSNotification(body){
    return this.http.post(urls.pushNotification, body, {headers:new HttpHeaders().set('Authorization', await this.getLoggedUserToken())})
  }

  //token used for agora chat sdk
  getChatSessionToken(body){
    return this.genericGETWithToken(environment.JOINUS+'user/chat-session-token?',body)
  }

  private group_id = new BehaviorSubject<string>('');

  get dataa$(): Observable<string> {
    return this.group_id.asObservable();
  }
  setGroupId(value: string): void {
    this.group_id.next(value);
  }
}
