import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private toastr: ToastrService) {}
  
  showToast(status,message:string) {
    if(status === 'Success'){
      this.toastr.success(message)
    }else if(status == "Warning"){
      this.toastr.warning(message)
    }else{
      this.toastr.error(message)
    }
  }

  showSpinner() {
    document.getElementById('global-spinner-overlay').style.display = 'block'
  }

  hideSpinner() {
    document.getElementById('global-spinner-overlay').style.display = 'none'
  }

  showMessageSpinner() {
    document.getElementById('message-spinner-overlay').style.display = 'block'
  }

  hideMessageSpinner() {
    document.getElementById('message-spinner-overlay').style.display = 'none'
  }

  errorHandler(err,skipSpinner?) {
      let errorMessage = (err.error&&err.error.message)?err.error.message:"Oops - Something went wrong, Please try again later.";
      if(!skipSpinner){
        document.getElementById('global-spinner-overlay').style.display = 'none';
      }
      if(err.status == 500){
        errorMessage = "Server Error, Please try again later"
      }
      this.showToast("Error",errorMessage)
  }
}
