import { environment } from "src/environments/environment";

export const urls = {
    reasonList: environment.JOINUS + 'classroom/report-reason/master',
    reportSend: environment.JOINUS + 'classroom/report/users',
    ratingSend: environment.JOINUS + 'classroom/rating/users',
    ratingList: environment.JOINUS + 'user/rating/list',
    reportList: environment.JOINUS + 'tutor/reports',
    reportHistory: environment.JOINUS + 'user/category/questions/list',
    reportDownload: environment.JOINUS + '/user/common/download/report',
    userProfile: environment.JOINUS + 'user/profile',
    userParent: environment.JOINUS + 'user/parent/email',
    userParentData: environment.JOINUS + 'user/common/parent-consent',
    submitParentForm: environment.JOINUS + 'user/common/parent-consent/action',
    subscriptionPurchase: environment.JOINUS + '/subscription/purchase',
    switchProfile: environment.JOINUS + 'user/common/switch/profile',
    getProfileData: environment.JOINUS + 'user/profile',
    updateProfileData: environment.JOINUS + 'user/common/profile',
    getSelectedLanguage: environment.JOINUS + 'user/common/language',
    deleteAccount: environment.JOINUS + 'user/common/account/delete',
    getavailabilityList: environment.JOINUS + 'tutor/availability/range',
    setAvailabilityStatus: environment.JOINUS + 'tutor/availbility/status',
    getReportsSettingData: environment.JOINUS + 'user/common/settings/reports',
    setReportSetting: environment.JOINUS + 'user/common/settings/reports',
    getNotificationList: environment.JOINUS + 'user/common/notification/list',
    clearAllNotification: environment.JOINUS + 'user/common/notification/delete',
    updateNotificaationStatus: environment.JOINUS + 'user/common/notification/status',
    repostQuestion: environment.JOINUS + 'user/repost/question/action',
    validateEmailPhoneNumber: environment.JOINUS + 'user/validate/entity/',
    getPermission: environment.JOINUS + 'student/classroom/settings',
    setPermission: environment.JOINUS + 'student/classroom/settings',
    pushNotification: environment.JOINUS + 'user/common/voip/notification',
}