import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Auth} from 'aws-amplify';
import { ApiService } from './Services/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private router:Router,private apiService: ApiService){
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
        try{
          let user = await Auth.currentAuthenticatedUser();
          this.apiService.userInfo = user.attributes 
          this.apiService.userInfo['username'] = user.username
          this.apiService.activeRoleIsStudent = user.attributes["custom:role"] == 3
          resolve(true)
        }catch (error){
          this.apiService.userInfo = null
          this.router.navigateByUrl('/auth/login');
          resolve(false)
        }
      })
  }
}
