import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
}
)
export class AwsUploadService {
  uploadFileToS3(file,successCallback,failureCallback,progessCallback?) {
    (new S3({
      accessKeyId: environment.awsBucket.accessKeyId,
      secretAccessKey: environment.awsBucket.secretAccessKey,
      region: environment.awsBucket.regionName
    })).upload({
      Bucket: environment.awsBucket.bucketName,
      Key: `public/${file.name}`,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    }, function (err, data) {
      if (err) {
        if(failureCallback){
          failureCallback(err)
        }
        return false;
      }
      successCallback(data)
      // console.log(data)
      return true;
    }).on("httpUploadProgress",(response)=>{if(progessCallback){progessCallback(response)}})
  }
}
